import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { MenuComponent } from "./components/menu";
import { MaterialModule } from "../material.module";
import { TranslateModule } from "@ngx-translate/core";
import { LightboxModule, LIGHTBOX_CONFIG } from "@ngx-gallery/lightbox";
import { GalleryModule } from "@ngx-gallery/core";

import {
  LocaleChangerDirective,
  ImagePreloadDirective,
  LocalNumberPipe,
  ExternalUrlDirective,
  NegativePositiveBg
} from "./directives";

import {
  SectionHeaderComponent,
  FeaturedListingsComponent,
  FeaturedListingCard,
  StoryListingCardComponent,
  NewsCardComponent,
  FeaturedNewsComponent,
  SponsorsComponent,
  NewFeaturesDialogComponent,
  LatamNavComponent,
  ErrorDialogComponent,
  MailchimpFormComponent,
  DemoDialogComponent,
  LoginComponent,
  ToasterNotificationComponent,
  InstallPromptComponent,
  AboutComponent,
  ProductsComponent,
  BlogRedirectComponent,
  ContactDialogComponent,
  PrivacyPolicyComponent,
  TermsOfUseComponent,
  PageNotFoundComponent,
  BreadcrumbNavComponent,
  NewVersionComponent,
  CapitalMarketsSectionComponent,
  SiilaIndexValuesComponent,
  CurrencyValuesComponent,
  BovespaValuesComponent,
  IfixValuesComponent,
  SelicValuesComponent,
  SiilaIndexReportComponent,
  LoadingSpinnerComponent
} from "./components";

import { LocalNumberWithSign } from "./pipes/number-sign.pipe";
import { TranslateStringCutter } from "./pipes/translateStringCutter";

import {
  NewDeviceDialogComponent,
  ExistingDeviceDialogComponent,
  NewDeviceSuccessComponent
} from "./components/dialogs";

import { ForgotDialogComponent } from "./components/dialogs/forgot-dialog";
import { InfoDialogComponent } from "./components/dialogs/info-dialog";

import { NguCarouselModule } from "@ngu/carousel";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { NgxSpinnerModule } from "ngx-spinner";
import { CarouselRestoreService, RegionLoaderService } from "./services";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ChangePasswordComponent } from "./components/static-pages/change-password/change-password.component";
import { LanguageSelectionComponent } from "./components/menu/language-navmenu/language-menu.component";
import {
  ServiceProviderCardComponent,
  ServiceProviderComponent
} from "./components/service-provider-card";
import { FeaturedListingService } from "../core/services/featured-listing.service";
import { HomeVideosService } from "../core/services/home-videos.service";
import { SiilaIndexService } from "./services/siila-index.service";
import { RouterModule } from "@angular/router";
import { AppTopBannerComponent } from "./components/app-top-banner/app-top-banner.component";
import { LocalNumberPipeSpot } from "./pipes/number-pipe";
import { ProductListComponent } from "./components/static-pages/product-list/product-list.component";
import { VideoPlayerComponent } from "./components/video-player/video-player.component";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { InfoCardComponent } from "./components/info-card/info-card.component";
import { MatSelectModule } from "@angular/material/select";
import { MemberComponent } from "./components/static-pages/member/member.component";
import { MarketAnalyticsComponent } from "./components/static-pages/market-analytics/market-analytics.component";
import { NumberSuffixPipe } from "./pipes/number-suffix.pipe";
import { BtnUpComponent } from "./components/btn-up/btn-up.component";
import { GrocsComponent } from "./components/static-pages/grocs/grocs.component";
import { SpotComponent } from "./components/static-pages/spot/spot.component";
import { ProductLayoutComponent } from "./components/static-pages/product-layout/product-layout.component";
import { LangSelectorPipe } from "./pipes/lang-selector.pipe";
import { SolutionLayoutComponent } from "./components/static-pages/solution-layout/solution-layout.component";
import { SolutionComponent } from "./components/static-pages/solution/solution.component";
import { SolutionListComponent } from "./components/static-pages/solution-list/solution-list.component";
import { FiiDataProComponent } from "./components/static-pages/fii-data-pro/fii-data-pro.component";
import { GlossaryComponent } from "./components/static-pages/glossary/glossary.component";
import { AngularStickyThingsModule } from "@w11k/angular-sticky-things";
import { PillarsComponent } from "./components/pillars/pillars.component";
import { ExclusiveAccessComponent } from "./components/exclusive-access/exclusive-access.component";
import { StateMarketComponent } from "./components/state-market/state-market.component";
import { IndustryLeaderComponent } from "./components/industry-leader/industry-leader.component";
import { FeaturedResourcesComponent } from "./components/featured-resources/featured-resources.component";
import { NewsInsightsComponent } from "./components/news-insights/news-insights.component";
import { HighlighsCardComponent } from "./components/highlighs-card/highlighs-card.component";
import { SiilaAcademyComponent } from "./components/static-pages/siila-academy/siila-academy.component";
import { SiilaMethodologyComponent } from "./components/siila-methodology/siila-methodology.component";
import { DownloadQuarterlyUpdateComponent } from "./components/download-quarterly-update/download-quarterly-update.component";
import { MarketAnalyticCardComponent } from "./components/market-analytic-card/market-analytic-card.component";
import { SiilaIndexValuesMxComponent } from "./components/capital-markets-section/siila-index-values-mx/siila-index-values-mx.component";
import { MetaTagService } from "./services/meta-tag.service";
import { MatTabsModule } from "@angular/material/tabs";
import { DetailNewsComponent } from "./components/detail-news/detail-news.component";
import { HomeNewsComponent } from "./components/home-news/home-news.component";
import { SearchNewsComponent } from "./components/search-news/search-news.component";
import { HighlightSearchPipe } from "./pipes/highlight-search.pipe";
import { NgbCarouselModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DetailCarouselComponent } from "./components/detail-news/detail-carousel/detail-carousel.component";
import { NgxCarouselModule } from "ngx-light-carousel";
import { HttpClientModule } from "@angular/common/http";
import { NewsNavigateService } from "./services/news.navigate.service";
import { PWAService } from "./services/pwa-service";
import { CaptchaService } from "./services/captcha.service";
import { PressComponent } from "./components/static-pages/press/press.component";
import { ShowImagesComponent } from "./components/show-images/show-images.component";
import { DetailPropertyComponent } from "./components/detail-property/detail-property.component";
import { AgmCoreModule } from "@agm/core";
import { environment } from "../../environments/environment";
import { ImageGalleryComponent } from "./components/image-gallery/image-gallery.component";
import { SendNotificationComponent } from "./components/send-notification/send-notification.component";
import { FeaturedListingsDetailComponent } from "./components/featured-listings/featured-listings-detail.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { DetailTransactionsComponent } from "./components/detail-transactions/detail-transactions.component";
import { TransactionsComponent } from "./components/transactions/transactions.component";
import { TransactionsTableComponent } from "./components/transactions-table/transactions-table.component";
import { AreasMeasurementsBrComponent } from "./components/detail-property/areas-measurements-br/areas-measurements-br.component";
import { AreasMeasurementsMxComponent } from "./components/detail-property/areas-measurements-mx/areas-measurements-mx.component";
import { PropertyFeaturesMxComponent } from "./components/detail-property/property-features-mx/property-features-mx.component";
import { PropertyFeaturesBrComponent } from "./components/detail-property/property-features-br/property-features-br.component";

@NgModule({
  declarations: [
    MenuComponent,
    FeaturedNewsComponent,
    NewsCardComponent,
    LocaleChangerDirective,
    SectionHeaderComponent,
    ImagePreloadDirective,
    ExternalUrlDirective,
    SponsorsComponent,
    FeaturedListingsComponent,
    FeaturedListingCard,
    StoryListingCardComponent,
    ServiceProviderCardComponent,
    ServiceProviderComponent,
    NewFeaturesDialogComponent,
    LatamNavComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    MailchimpFormComponent,
    DemoDialogComponent,
    LoginComponent,
    ToasterNotificationComponent,
    InstallPromptComponent,
    AboutComponent,
    ProductsComponent,
    BlogRedirectComponent,
    PrivacyPolicyComponent,
    ChangePasswordComponent,
    TermsOfUseComponent,
    SectionHeaderComponent,
    ContactDialogComponent,
    ForgotDialogComponent,
    NewDeviceDialogComponent,
    ExistingDeviceDialogComponent,
    NewDeviceSuccessComponent,
    PageNotFoundComponent,
    BreadcrumbNavComponent,
    LocalNumberPipe,
    LanguageSelectionComponent,
    NewVersionComponent,
    CapitalMarketsSectionComponent,
    SiilaIndexValuesComponent,
    CurrencyValuesComponent,
    BovespaValuesComponent,
    IfixValuesComponent,
    SelicValuesComponent,
    NegativePositiveBg,
    LocalNumberWithSign,
    TranslateStringCutter,
    SiilaIndexReportComponent,
    LoadingSpinnerComponent,
    AppTopBannerComponent,
    LocalNumberPipeSpot,
    ProductListComponent,
    VideoPlayerComponent,
    InfoCardComponent,
    MemberComponent,
    MarketAnalyticsComponent,
    NumberSuffixPipe,
    BtnUpComponent,
    GrocsComponent,
    SpotComponent,
    ProductLayoutComponent,
    LangSelectorPipe,
    SolutionLayoutComponent,
    SolutionComponent,
    SolutionListComponent,
    FiiDataProComponent,
    GlossaryComponent,
    PillarsComponent,
    ExclusiveAccessComponent,
    StateMarketComponent,
    IndustryLeaderComponent,
    FeaturedResourcesComponent,
    NewsInsightsComponent,
    HighlighsCardComponent,
    SiilaAcademyComponent,
    SiilaMethodologyComponent,
    DownloadQuarterlyUpdateComponent,
    MarketAnalyticCardComponent,
    SiilaIndexValuesMxComponent,
    DetailNewsComponent,
    HomeNewsComponent,
    SearchNewsComponent,
    HighlightSearchPipe,
    DetailCarouselComponent,
    PressComponent,
    ShowImagesComponent,
    DetailPropertyComponent,
    ImageGalleryComponent,
    SendNotificationComponent,
    FeaturedListingsDetailComponent,
    DetailTransactionsComponent,
    TransactionsComponent,
    TransactionsTableComponent,
    AreasMeasurementsBrComponent,
    AreasMeasurementsMxComponent,
    PropertyFeaturesMxComponent,
    PropertyFeaturesBrComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    NguCarouselModule,
    RouterModule,
    NgxSpinnerModule,
    SlickCarouselModule,
    FontAwesomeModule,
    NgbCarouselModule,
    YouTubePlayerModule,
    GalleryModule.withConfig({
      //loadingMode: "indeterminate",
      loadingStrategy: "lazy",
      imageSize: "contain",
      loadingError: "Unable to load image.",
      thumbLoadingError: "..."
    }),
    LightboxModule,
    AngularStickyThingsModule,
    NgbModule,
    NgxCarouselModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ["geometry", "places"],
      apiVersion: "quarterly"
    }),
    MaterialModule,
    NgApexchartsModule
  ],
  exports: [
    MenuComponent,
    LocaleChangerDirective,
    SectionHeaderComponent,
    ImagePreloadDirective,
    ExternalUrlDirective,
    SponsorsComponent,
    FeaturedListingsComponent,
    FeaturedListingCard,
    StoryListingCardComponent,
    ServiceProviderCardComponent,
    ServiceProviderComponent,
    NewFeaturesDialogComponent,
    FeaturedNewsComponent,
    NewsCardComponent,
    LatamNavComponent,
    MailchimpFormComponent,
    NguCarouselModule,
    ErrorDialogComponent,
    InfoDialogComponent,
    DemoDialogComponent,
    LoginComponent,
    ContactDialogComponent,
    ForgotDialogComponent,
    ToasterNotificationComponent,
    InstallPromptComponent,
    AboutComponent,
    ProductsComponent,
    BlogRedirectComponent,
    PrivacyPolicyComponent,
    ChangePasswordComponent,
    TermsOfUseComponent,
    SectionHeaderComponent,
    NewDeviceDialogComponent,
    ExistingDeviceDialogComponent,
    NewDeviceSuccessComponent,
    BreadcrumbNavComponent,
    LocalNumberPipe,
    NumberSuffixPipe,
    LangSelectorPipe,
    LanguageSelectionComponent,
    NewVersionComponent,
    CapitalMarketsSectionComponent,
    SiilaIndexValuesComponent,
    CurrencyValuesComponent,
    BovespaValuesComponent,
    IfixValuesComponent,
    SelicValuesComponent,
    NegativePositiveBg,
    LocalNumberWithSign,
    TranslateStringCutter,
    SiilaIndexReportComponent,
    LoadingSpinnerComponent,
    AppTopBannerComponent,
    LocalNumberPipeSpot,
    VideoPlayerComponent,
    InfoCardComponent,
    PillarsComponent,
    SolutionListComponent,
    BtnUpComponent,
    ExclusiveAccessComponent,
    StateMarketComponent,
    IndustryLeaderComponent,
    FeaturedResourcesComponent,
    HighlighsCardComponent,
    NewsInsightsComponent,
    SiilaMethodologyComponent,
    DownloadQuarterlyUpdateComponent,
    MarketAnalyticCardComponent,
    TranslateModule,
    HighlightSearchPipe,
    DetailCarouselComponent,
    PressComponent,
    FeaturedListingsDetailComponent,
    AreasMeasurementsBrComponent,
    AreasMeasurementsMxComponent,
    PropertyFeaturesMxComponent,
    PropertyFeaturesBrComponent
  ],
  providers: [
    CarouselRestoreService,
    FeaturedListingService,
    HomeVideosService,
    SiilaIndexService,
    LocalNumberPipe,
    LocalNumberPipeSpot,
    MetaTagService,
    HighlightSearchPipe,
    DatePipe,
    NewsNavigateService,
    CaptchaService
  ]
})
export class SharedModule {}
